
@import "~@picocss/pico/scss/pico";


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400&display=swap');

p {
  font-family: 'Karla', sans-serif;
}

h1 {
  font-family: 'Montserrat', sans-serif;
}

/* H3 to H5 styles */
h2, h3, h4, h5 {
  font-family: 'Francois One', sans-serif;
}

.level {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  font-size: 20px;
  padding: 10px;
}

.special {
  visibility: hidden;
}

.mast {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.mast-text {
  margin-left: 20px;
}

.headshot {
  height: auto;
  max-width: 400px;
  min-width: 200px;
  border-radius: 30%;
  margin-right: 20px;
}

nav {
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
}

html[data-theme="light"] nav {
  background-color: #f5f5f5;
}


html[data-theme="dark"] nav {
  background-color: #2c2c2c;
}

footer {
  @extend nav;
  padding-bottom: 0px;
  margin-top: 40px;
}

html[data-theme="light"] .level {
  background-color: #e0e0e0;
}


html[data-theme="dark"] .level {
  background-color: #2c2c2c;
}

.talk-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem; /* Adjust the gap between items */
  align-items: start; /* Align items to the start */
}

.deck {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  img {
    max-width: 350px;
    height: auto;
    margin-bottom: 5px;
  }
  a.dl-link {
    width: 100%;
    max-width: 350px;
    cursor: pointer;
  }
}

.talk-description {
  flex: 1;
  margin-left: 0;
  padding-left: 10px;
  text-align: left;
}

.central-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-width: 75%;
  max-width: 100%;
  height: auto;
  figcaption {
    text-align: center;
  }
}
